import { useState, useEffect } from "react";
import clsx from "clsx";
import style from "./Navbar.module.scss";
import Logo from "components-shared/Logo";
import { format } from "date-fns";
import LockImg from "assets/images/lock-icon.svg";

import Hamburger from "hamburger-react";

import GoogleLoginBtn from "components-shared/GoogleLoginBtn";

import { useNavbar } from "./useNavbar";
import { capitalizeFirstLetter, handleLogout, isLoggedIn } from "utils/helpers";
import { useAppContext } from "contexts/AppContext";

import { IoIosArrowDown } from "react-icons/io";
import {
  NavLinks,
  NavLinksChannelPartner,
  NavLinksChannelPartnerReg,
  NavLinksReg,
} from "utils/consts";
import Button from "components-shared/Button/Button";
import Dropdown from "components-shared/Dropdown/DropDown";

import { motion, useAnimation, useInView } from "framer-motion";
import { Modal } from "antd";
import Registration from "./Registration";
import { useLocation, useNavigate } from "react-router-dom";

import Input from "components-shared/Form/Input/PartnerInput";

import { FaChevronDown } from "react-icons/fa";
import styled from "styled-components";

// const currNavLinks = NavLinks[localStorage.getItem("role")];

export default function Navbar() {
  const {
    state,
    handleClickLink,
    handleClick,
    onChange,
    isOpenHam,
    setOpenHam,
    handleClickChannelPartnerLink,
  } = useNavbar();

  const {
    state: user,
    handleSuccessGoogleLogin,
    setIsCloseProfileUpdateModal,
    handleSuccessGoogleLoginChannelPartner,
  } = useAppContext();

  const { userProfile } = user;

  const location = useLocation();
  const pattern = /^\/(resume|cv)\/[0-9a-f]{24}$/;

  let date =
    user.userProfile.accessExpiryDate !== undefined &&
    user.userProfile.accessExpiryDate;

  const Ispremuim = user?.userProfile?.isPremium;

  const formattedDate = format(date, "dd MMMM yyyy");
  const accessExpiryTime = {
    label: (
      <span style={{ fontWeight: "bold" }}>
        <>
          {Ispremuim ? (
            <span>
              Subscription ends on <br></br>
              <span style={{ color: "#ff0000c4" }}>{formattedDate}</span>
            </span>
          ) : (
            <span>Freemium</span>
          )}
        </>
      </span>
    ),
    disabled: true,
  };
  const newNavLinks = [
    ...(!userProfile.isNew ? NavLinks : NavLinksReg),
    !userProfile.isNew && {
      type: "divider",
    },
    !userProfile.isNew && accessExpiryTime,
  ];
  const [isScrolled, setIsScrolled] = useState(false);
  // const [isOpenHam, setOpenHam] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let navigate = useNavigate();

  const controlHeader = () => {
    if (window.scrollY > lastScrollY) {
      controls.start({ y: "-100%" });
    } else {
      controls.start({ y: "0%" });
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlHeader);
    return () => {
      window.removeEventListener("scroll", controlHeader);
    };
  }, [lastScrollY, controls]);

  return (
    <>
      <motion.header
        // initial={{ y: "0%" }}
        // animate={controls}
        // transition={{ ease: "easeOut", duration: 0.3 }}
        className={clsx(
          style.subtitle,
          "sticky top-0 left-0 right-0 z-50 navbarTop",
          isScrolled ? "bg-white" : "bg-transparent"
        )}
        // style={{ borderBottom: "1px solid #aaa" }}
      >
        <div
          className={clsx(
            style.navbar,
            "flex mx-4 md:mx-16 mt-2 mb-2 justify-between items-center"
          )}
        >
          <span
            className={clsx(style.logo, "cursor-pointer")}
            data-testid="logo-image"
          >
            <Logo theme="light" w="150" />
          </span>

          <div>
            <div className="md:hidden">
              {!pattern.test(location.pathname) ? (
                <Button
                  type="default"
                  shape="round"
                  onClick={() => setOpenHam(!isOpenHam)}
                  className="flex items-center gap-1"
                >
                  <span>
                    {isLoggedIn()
                      ? user?.userProfile?.name.split(" ")[0].slice(0, 18)
                      : "Sign up"}
                  </span>{" "}
                  <FaChevronDown />
                </Button>
              ) : (
                <div>
                  {!isLoggedIn() ? (
                    <li data-testId="google-login-btn">
                      <GoogleLoginBtn onSuccess={handleSuccessGoogleLogin} />
                    </li>
                  ) : (
                    <DropDiv style={{ maxWidth: "155px" }}>
                      <Dropdown
                        label={user.userProfile.name.split(" ")[0].slice(0, 9)}
                        items={newNavLinks}
                        onClick={handleClickLink}
                        trigger={["click"]}
                      />
                    </DropDiv>
                  )}
                </div>
              )}

              {/* <Hamburger
                color="#2846bd"
                toggled={isOpenHam}
                toggle={setOpenHam}
              /> */}
            </div>
            <nav
              className={`"md:block md:bg-transparent md:overflow-visible absolute md:relative z-[1010] md:h-full transition-all duration-200 ease left-0 border w-full z-20 bg-[#fff] ${
                !isOpenHam && "h-[0px] overflow-hidden  z-[1010] md:h-full"
              }"`}
            >
              <ol className="flex items-center m-0 gap-4 list-none flex-col md:flex-row py-4 md:py-0">
                {location.pathname.includes("/channelpartner") ? (
                  // <Button
                  //   className="font-semibold cursor-pointer"
                  //   type="primary"
                  //   shape="round"
                  //   size="large"
                  //   onClick={() => handleClick("openModal")}
                  // >
                  //   Log In / Sign Up
                  // </Button>
                  <div>
                    {!isLoggedIn() ? (
                      <li data-testId="google-login-btn">
                        <GoogleLoginBtn
                          onSuccess={handleSuccessGoogleLoginChannelPartner}
                        />
                      </li>
                    ) : (
                      <DropDiv style={{ maxWidth: "155px" }}>
                        <Dropdown
                          label={user.userProfile.name
                            .split(" ")[0]
                            .slice(0, 9)}
                          items={
                            location.pathname === "/channelpartner/registration"
                              ? user.userProfile.isNew
                                ? NavLinksChannelPartnerReg
                                : NavLinksChannelPartner
                              : user.userProfile.isNew
                              ? NavLinksChannelPartnerReg
                              : NavLinksChannelPartner
                          }
                          onClick={handleClickChannelPartnerLink}
                          trigger={["click"]}
                        />
                      </DropDiv>
                    )}
                  </div>
                ) : (
                  !pattern.test(location.pathname) && (
                    <>
                      {isLoggedIn() && (
                        <li className="!text-[#fff] font-bold md:!hidden">
                          <Button
                            type="primary"
                            shape="round"
                            className={clsx(
                              "font-bold",
                              location.pathname === "/pricing" && "text-[#fff] "
                            )}
                            onClick={() => handleClick("myDocuments")}
                          >
                            My Documents
                          </Button>
                        </li>
                      )}

                      <li className="text-[#0c8ce9] font-bold">
                        <Button
                          type="text"
                          className={clsx(
                            "font-bold",
                            location.pathname === "/templates" &&
                              "text-[#0c8ce9]"
                          )}
                          onClick={() => handleClick("templates")}
                        >
                          Templates
                        </Button>
                      </li>
                      <li className="text-[#0c8ce9] font-bold">
                        <Button
                          type="text"
                          className={clsx(
                            "font-bold",
                            location.pathname === "/pricing" && "text-[#0C8CE9]"
                          )}
                          onClick={() => handleClick("pricing")}
                        >
                          Pricing
                        </Button>
                      </li>
                      {isLoggedIn() && (
                        <li className="text-[#0c8ce9] font-bold">
                          <Button
                            type="text"
                            className={clsx(
                              "font-bold",
                              location.pathname === "/myprep" &&
                                "text-[#0C8CE9]"
                            )}
                            onClick={() => handleClick("myprep")}
                          >
                            My Prep
                          </Button>
                        </li>
                      )}
                      {/* <li className={clsx(style.locked)}>
                        <Button
                          type="text"
                          className={clsx("font-bold flex items-center gap-2")}
                        >
                          <span className="block">For Companies</span>
                          <img src={LockImg} alt="lock" className="w-3" />
                        </Button>
                      </li> */}
                      {isLoggedIn() && (
                        <li className="!text-[#fff] !hidden md:!block font-bold">
                          {!userProfile.isNew && (
                            <Button
                              type="primary"
                              shape="round"
                              className={clsx(
                                "font-bold ",
                                location.pathname === "/pricing" &&
                                  "text-[#fff] "
                              )}
                              onClick={() => handleClick("myDocuments")}
                            >
                              My Documents
                            </Button>
                          )}
                        </li>
                      )}

                      {isLoggedIn() && (
                        <>
                          <li className="md:!hidden">
                            <Button
                              className="font-bold text-sm"
                              type="text"
                              shape="round"
                              size="large"
                              onClick={() => handleClick("faq")}
                            >
                              Help
                            </Button>
                          </li>
                          <li className="md:!hidden">
                            <Button
                              className="font-bold text-sm"
                              type="text"
                              shape="round"
                              size="large"
                              onClick={() => handleLogout()}
                            >
                              Logout
                            </Button>
                          </li>
                        </>
                      )}
                    </>
                  )
                )}

                {!location.pathname.includes("/channelpartner") && (
                  <div>
                    {!isLoggedIn() ? (
                      <li data-testId="google-login-btn">
                        <Button
                          type="primary"
                          size="large"
                          shape="round"
                          className={clsx("w-fit relative")}
                        >
                          {isLoggedIn() ? "My Documents" : "Login / SignUp"}
                          {!isLoggedIn() && (
                            <GoogleLoginBtn
                              onSuccess={handleSuccessGoogleLogin}
                              className="opacity-0 absolute left-0 top-0"
                            />
                          )}
                        </Button>
                      </li>
                    ) : (
                      // !userProfile.isNew && (
                      <DropDiv
                        className="hidden md:block"
                        style={{ maxWidth: "155px" }}
                      >
                        <Dropdown
                          label={capitalizeFirstLetter(
                            user.userProfile.name.split(" ")[0]
                          )}
                          items={newNavLinks}
                          onClick={handleClickLink}
                          trigger={["click"]}
                          style={{ width: "100%" }}
                        />
                      </DropDiv>
                      // )
                    )}
                  </div>
                )}
              </ol>
            </nav>
          </div>
        </div>
        {/* <div className="!container relative right-[4rem] bg-[#fff] md:bg-transparent  ">
          <div
            className={
              state.isOpen
                ? "hidden"
                : "flex bg-[#fff] w-full md:w-fit bottom-[-12em] md:bottom-auto md:-top-2 z-[9999] flex-col gap-4 absolute md:right-0 shadow-xl py-2 rounded-md"
            }
          >
            {NavLinks.map((nav) => (
              <button
                className="bg-transparent px-4"
                key={nav.name}
                onClick={handleClickLink.bind(this, nav)}
              >
                {nav.name}
              </button>
            ))}
          </div>
        </div> */}
      </motion.header>
      <Registration
        isOpen={user.isOpenProfileUpdateModal}
        onCancel={() => setIsCloseProfileUpdateModal(false)}
        state={state}
        onChange={onChange}
        handleEmailUpdate={() => handleClick("emailUpdate")}
      />

      <Modal
        title="Login / SignUp"
        open={state.modalVisible}
        width={400}
        footer={null}
        maskClosable={false}
        closable={true}
        onCancel={() => handleClick("closeModal")}
      >
        <form
        // onChange={handleDataSetForm}
        // onSubmit={(e) => handleClick("createDataSet", e)}
        >
          <div className="flex flex-col mt-3 gap-4">
            <Input
              className="col-span-4"
              label="Registered Email Address"
              placeholder="Registered Email Address"
              onChange={(e) => console.log(e.target.value)}
            />

            <Input
              className="col-span-4"
              label="Password"
              placeholder="Password"
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div className="text-right pt-2 pb-2">Forgot Password?</div>
          <Button type="primary" block>
            Login
          </Button>
          <div className="text-center pt-2">
            Don’t have an account?{" "}
            <span className="text-[#0C8CE9]">Register</span>
          </div>
          <div className="text-center pt-2">OR</div>
          <Button type="primary" ghost block>
            <div className="flex gap-5 items-center justify-center">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.4 9.1V11.8333H16.9334C16.7334 13.3667 16.2225 14.4892 15.4442 15.2775C14.4884 16.2333 13 17.2775 10.4 17.2775C6.37753 17.2775 3.23336 14.0333 3.23336 10.0108C3.23336 5.98833 6.37753 2.74417 10.4 2.74417C12.5667 2.74417 14.1559 3.6 15.3225 4.7L17.245 2.7775C15.6225 1.2 13.4442 0 10.4 0C4.88919 0 0.255859 4.48917 0.255859 10C0.255859 15.5108 4.88919 20 10.4 20C13.3775 20 15.6225 19.0225 17.3775 17.2C19.1775 15.4 19.7442 12.8558 19.7442 10.8108C19.7442 10.1775 19.7 9.58833 19.6 9.1H10.4Z"
                    fill="#4285F4"
                  />
                </svg>
              </div>
              <div className="text-[#000]">Continue with Google</div>
            </div>
          </Button>
        </form>
      </Modal>
    </>
  );
}

// eslint-disable-next-line no-lone-blocks
{
  /* <div
            className={clsx(
              style.dropdown,
              "withPadding",
              isOpenHam && style.open
            )}
          >
            <ol>
              <li>Free Templates</li>
              <li className={style.locked}>
                For Companies
                <span className={style.icon}>
                  <img src={LockImg} alt="lock" />
                </span>
              </li>
            </ol>
          </div> */
}
const DropDiv = styled.div`
  button {
    max-width: 155px;
    /* min-width: 155px; */
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    font-size: 14px;
    svg {
      transform: scale(2);
    }
    div {
      width: 100%;
      .ant-space-item {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;
