import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";

import Preloader from "components-shared/Preloader/Preloader";

import { AnimatePresence } from "framer-motion";
import {
  useWithAuth,
  useWithAuthChannelPartner,
  useWithAuthChannelPartnerRegistration,
} from "./useWithAuth";
import { Roles } from "utils/consts";
import useGlobalEffects from "./useGlobalEffect";
import { lazyRetry } from "./Lazyretry";
import { useAppContext } from "contexts/AppContext";

const Login = lazy(() => lazyRetry(() => import("components/Login")));

const MyContract = lazy(() =>
  lazyRetry(() => import("components/ChannelPartner/MyContracts"))
);
const Landing = lazy(() => lazyRetry(() => import("components/Landing")));
const CreateResume = lazy(() =>
  lazyRetry(() => import("components/CreateResume"))
);
const CoverLetterEditor = lazy(() =>
  lazyRetry(() => import("components/CoverLetterEditor"))
);
const Pricing = lazy(() => lazyRetry(() => import("components/Pricing")));
// const Approval = lazy(() => lazyRetry(() => import("components/ChannelPartner/Approval")));
const PageNotFound = lazy(() =>
  lazyRetry(() => import("components-shared/ErrorBoundary/RouteError"))
);
const Faq = lazy(() => lazyRetry(() => import("components/FAQ")));
const PrepTips = lazy(() => lazyRetry(() => import("components/PrepTips")));
// const MyAccount = lazy(() => lazyRetry(() => import("components/AllDataSets")));
const CVTemplate = lazy(() => lazyRetry(() => import("components/CVTemplate")));
const Dashboard = lazy(() => lazyRetry(() => import("components/Dashboard")));
const Editor = lazy(() => lazyRetry(() => import("components/Editor/Editor")));
const MyAccount = lazy(() => lazyRetry(() => import("components/MyAccount")));
const Registration = lazy(() =>
  lazyRetry(() => import("components/Registration"))
);
const PaymentSuccess = lazy(() =>
  lazyRetry(() =>
    import("components-shared/PaymentHandlerScreens/PaymentSuccess")
  )
);
const PaymentFailed = lazy(() =>
  lazyRetry(() =>
    import("components-shared/PaymentHandlerScreens/PaymentFailed")
  )
);
const Mypitch = lazy(() => lazyRetry(() => import("components/MyPitch")));
const ResumeTemplates = lazy(() =>
  lazyRetry(() => import("components/ResumeTemplates/ResumeTemplates"))
);
const AllTemplates = lazy(() =>
  lazyRetry(() => import("components/AllTemplates"))
);
const DataSet = lazy(() =>
  lazyRetry(() => import("components/DataSets/DataSet"))
);
const ChannelPartnerLanding = lazy(() =>
  lazyRetry(() => import("components/ChannelPartner/ChannelPartnerLanding"))
);
const ChannelPartnerBenefit = lazy(() =>
  lazyRetry(() => import("components/ChannelPartner/ChannelPartnerBenefit"))
);
const Approval = lazy(() =>
  lazyRetry(() => import("components/ChannelPartner/Approval"))
);
const ChannelPartnerReg = lazy(() =>
  lazyRetry(() =>
    import("components/ChannelPartner/ChannelPartnerReg/ChannelPartnerReg")
  )
);
const ChannelPartnerProfile = lazy(() =>
  lazyRetry(() => import("components/ChannelPartner/ChannelPartnerProfile"))
);
const PrivacyPolicy = lazy(() =>
  lazyRetry(() => import("components/Policy/PrivacyPolicy"))
);
const CookiePolicy = lazy(() =>
  lazyRetry(() => import("components/Policy/CookiePolicy"))
);
const PircingPolicy = lazy(() =>
  lazyRetry(() => import("components/Policy/PricingPolicy"))
);
const RefundPolicy = lazy(() =>
  lazyRetry(() => import("components/Policy/RefundPolicy"))
);
const AdminLogin = lazy(() =>
  lazyRetry(() => import("components/AdminLogin/AdminLogin"))
);
const Projects = lazy(() =>
  lazyRetry(() => import("components/Projects/Projects"))
);
const TermsAndConditions = lazy(() =>
  lazyRetry(() => import("components/Policy/TermsAndConditions"))
);

const ContactUs = lazy(() =>
  lazyRetry(() => import("components/Policy/ContactUS"))
);
const ShippingPolicy = lazy(() =>
  lazyRetry(() => import("components/Policy/ShippingPolicy"))
);

const MySubscription = lazy(() =>
  lazyRetry(() => import("components/MyAccount/MySubscription"))
);

const Role = lazy(() => lazyRetry(() => import("components/Myprep/Role/Role")));
const MyPrep = lazy(() =>
  lazyRetry(() => import("components/Myprep/Role/Myprep"))
);

export default function AppRouter() {
  const { authorize } = useWithAuth();
  const { authorizeChannelPartner } = useWithAuthChannelPartner();
  const { state: user } = useAppContext();

  const { userProfile } = user;

  // const { authorizeChannelPartnerRegistrion } =
  //   useWithAuthChannelPartnerRegistration();

  // useGlobalEffects();
  const userRole = localStorage.getItem("role");
  return (
    <Suspense fallback={<Preloader />}>
      <AnimatePresence mode="wait">
        <Routes>
          {/* element={authorize(<PublishedProblemSets />, [Roles.User])} */}
          {/* <Route path="/" element={<Navigate to={Routes.Home} />} /> */}
          <Route path={routes.Login} element={<Login />} />

          <Route path="*" element={<PageNotFound />} />
          <Route path={routes.MySubscription} element={<MySubscription />} />
          <Route
            path={routes.Home}
            element={
              userRole === "channelpartner" ? (
                authorize(<Landing />, [Roles.Student])
              ) : (
                <Landing />
              )
            }
          />

          <Route
            path={routes.Dashboard}
            element={authorize(<Dashboard />, [Roles.User], userProfile.isNew)}
          />

          <Route
            path={routes.NewRsume}
            element={authorize(<CreateResume />, [Roles.User])}
          />
          <Route path={routes.ResumeTemplates} element={<ResumeTemplates />} />
          <Route path={routes.Pricing} element={<Pricing />} />
          <Route path={routes.Admin} element={<AdminLogin />} />
          <Route path={routes.Projects} element={<Projects />} />
          <Route path={routes.Mypitch} element={<Mypitch />} />
          <Route path={routes.PaymentSuccess} element={<PaymentSuccess />} />
          <Route path={routes.PaymentFailed} element={<PaymentFailed />} />
          {userProfile.isNew && (
            <Route path={routes.Register} element={<Registration />} />
          )}
          <Route
            path={`${routes.Editor}/:resumeId`}
            element={authorize(<Editor />, [Roles.User])}
          />
          <Route
            path={`${routes.CVEditor}/:CVId`}
            element={authorize(<CoverLetterEditor />, [Roles.User])}
          />
          <Route path={routes.Faq} element={<Faq />} />
          <Route path={routes.Prep} element={<PrepTips />} />
          <Route path={routes.CVTemplates} element={<CVTemplate />} />
          {/* <Route path={routes.Contracts} element={<MyContract />} /> */}
          <Route
            path={routes.Contracts}
            element={authorizeChannelPartner(<MyContract />, [Roles.Partner])}
          />
          <Route
            path={routes.MyAccount}
            element={authorize(<MyAccount />, [Roles.User])}
          />
          <Route
            path={`${routes.Datasets}/:datasetId`}
            element={authorize(<DataSet />, [Roles.User])}
          />
          <Route path={routes.Templates} element={<AllTemplates />} />
          {/* <Route path="dsf" element={<ResumeTemplate />} /> */}
          <Route
            path={routes.ChannelPartnerLanding}
            element={
              userRole === "student" ? (
                authorizeChannelPartner(<ChannelPartnerLanding />, [
                  Roles.Partner,
                ])
              ) : (
                <ChannelPartnerLanding />
              )
            }
          />
          <Route
            path={routes.ChannelPartnerBenefit}
            element={authorizeChannelPartner(<ChannelPartnerBenefit />, [
              Roles.Partner,
            ])}
          />
          <Route
            path={routes.ChannelPartnerReg}
            element={authorizeChannelPartner(<ChannelPartnerReg />, [
              Roles.Partner,
            ])}
            // element={<ChannelPartnerReg />}
          />
          <Route
            path={routes.ChannelPartnerProfile}
            element={authorizeChannelPartner(<ChannelPartnerProfile />, [
              Roles.Partner,
            ])}
          />
          <Route
            path={routes.Approval}
            element={authorizeChannelPartner(<Approval />, [Roles.Partner])}
          />
          <Route path={routes.PrivacyPolicy} element={<PrivacyPolicy />} />
          <Route path={routes.CookiePolicy} element={<CookiePolicy />} />
          <Route
            path={routes.TermsAndConditions}
            element={<TermsAndConditions />}
          />
          <Route
            path={routes.TermsAndConditions}
            element={<TermsAndConditions />}
          />
          <Route path={routes.PricingPolicy} element={<PircingPolicy />} />
          <Route path={routes.RefundPolicy} element={<RefundPolicy />} />
          <Route path={routes.ContactUS} element={<ContactUs />} />
          <Route path={routes.ShippingPolicy} element={<ShippingPolicy />} />
          <Route
            path={`${routes.Myprep}/:id`}
            element={authorize(<Role />, [Roles.User])}
          />
          <Route
            path={`${routes.Myprep}`}
            element={authorize(<MyPrep />, [Roles.User])}
          />
        </Routes>
      </AnimatePresence>
    </Suspense>
  );
}
